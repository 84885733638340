<template>
  <div>
    <div class="f-col gap-2" :class="{ center: variant === 'carousel' }">
      <h3 class="title-3">
        {{ title }}
      </h3>
      <cms-rich-text :content="{ richText: description }" />
      <span class="lg:ml-a">
        <base-link
          v-if="target && containerId"
          class="items-center pb-6 pt-2 fw-medium i-flex md:pb-4"
          :target="target.targetAttribute"
          :to="target.url"
          @click="$gtm.push('socialNative.onClickExploreGallery')"
        >
          {{ $t.exploreGallery }}
          <vf-icon class="ml-2" name="arrow" />
        </base-link>
        <base-button v-if="variant === 'masonry' && !containerId" class="items-center pb-6 pt-2 fw-medium i-flex md:pb-4" @click="openAddYourPhoto()">
          {{ $t.addPhoto }}
          <vf-icon class="ml-2" name="arrow" />
        </base-button>
      </span>
    </div>
    <cms-ugc-social-native-masonry v-if="variant === 'masonry' && data.length">
      <template
        v-for="({ images, id, likes, _embedded }, index) in data"
        :key="index"
        #[`element-${index}`]
      >
        <div class="relative full p-1">
          <base-picture
            v-if="images.normal"
            :alt="ugcAltText(_embedded)"
            class="full"
            fit="cover"
            height="640"
            :src="images.normal"
            width="516"
            @click="openShopTheLook(id)"
          />
          <div class="absolute-0 m-1 f-col center gap-6 bg-black bg-op-75 op-0 media-touch:hidden @hover:op-100 focus-within:op-100">
            <div v-if="likes" class="f-col center c-white">
              <vf-icon name="heart" size="md" />
              <span>{{ likes }}</span>
            </div>
            <span class="w-full break-words text-center text-md c-white">@{{ _embedded.uploader.username }}</span>
            <vf-button
              :aria-label="ctaAriaLabel(_embedded)"
              variant="tertiary"
              @click="openShopTheLook(id)"
            >
              {{ $t.shopTheLookTileCta }}
            </vf-button>
          </div>
        </div>
      </template>
    </cms-ugc-social-native-masonry>
    <vf-carousel
      v-else-if="data.length"
      class="my-4 md:px-10"
      class-controls="md:-mx-10"
      loop
    >
      <div
        v-for="({ id, images, _embedded }, key) in data"
        :key
        class="relative aspect-square w-1/2 cursor-pointer p-1 lg:w-1/6 md:w-1/5"
        @click="openShopTheLook(id)"
      >
        <base-picture
          v-if="images.normal"
          :alt="ugcAltText(_embedded)"
          class="full"
          fit="cover"
          height="640"
          :src="images.normal"
          width="516"
        />
        <div
          :aria-label="ctaAriaLabel(_embedded)"
          class="absolute-0 m-1 flex center gap-6 bg-black bg-op-75 op-0 @hover:op-100 focus-within:op-100"
          role="button"
          tabindex="0"
          @keypress="openShopTheLook(id)"
        >
          <span class="w-full break-words text-center text-md c-white">@{{ _embedded.uploader.username }}</span>
        </div>
      </div>
    </vf-carousel>
    <modal-shop-the-look class-content="lg:w-4/5" />
    <modal-add-your-photo class-content="h-1/2 md:h-2/3" />
  </div>
</template>

<script lang="ts" setup>
import type { UGCSocialNativeContent } from '#types/components/cms/ugc'
import type { SocialNativeErrorMessage, SocialNativeMedia, SocialNativeResponseMedia } from '#root/api/clients/social-native/data-contracts'

const { content } = defineProps<{
  content: UGCSocialNativeContent
}>()

const { $gtm, $t } = useNuxtApp()

const {
  variant,
  title,
  description,
  target,
  containerId
} = content

const route = useRoute()
const {
  getAuthentication,
  getMediaByCustomer,
  getMediaByTagKey,
  getMediaByStream
} = useSocialNative()

let data = [] as SocialNativeMedia[]
try {
  let media
  if (variant === 'masonry' && containerId) {
    media = (await getMediaByStream({ streamId: containerId }))
  }
  else if (variant === 'masonry') {
    const customerId = await getAuthentication() || ''
    media = await getMediaByCustomer({ customerId })
  }
  else if (variant === 'carousel') {
    media = await getMediaByTagKey({ tagKey: getUrlId(route.path, 'Product') })
  }
  data = (media.data as Exclude<SocialNativeResponseMedia['data'], SocialNativeErrorMessage>)._embedded.media
}
catch (error) {
  console.error(error)
}

const ModalShopTheLook = createModal('social-native')
const openShopTheLook = (id) => {
  $gtm.push('socialNative.onClickOpenModal', 'Shop the Look')
  ModalShopTheLook.open({ mediaId: id, media: data }).then((automatic) => {
    if (!automatic)
      $gtm.push('socialNative.onClickCloseModal', 'Shop the Look')
  })
}
const ModalAddYourPhoto = createModal('add-your-photo')
const openAddYourPhoto = () => {
  $gtm.push('socialNative.onClickOpenModal', 'Add Your Photo')
  ModalAddYourPhoto.open().then((automatic) => {
    if (!automatic)
      $gtm.push('socialNative.onClickCloseModal', 'Add Your Photo')
  })
}

const ugcAltText = (product) => {
  const item = product['streams:all']._embedded.stream[0]
  return item.product_url
    ? `${$t.userSubmitted} ${item.name}`
    : `${$t.userSubmitted} ${$t.brandName} ${$t.products.one}`
}
const ctaAriaLabel = (product) => {
  const item = product['streams:all']._embedded.stream[0]
  return item.product_url
    ? `${$t.shopTheLookTileCta} ${item.name}`
    : $t.shopTheLookTileCta
}
</script>
